/*eslint-disable no-unused-vars*/
import { createPopper } from '@popperjs/core';
import * as bootstrap from 'bootstrap';

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

import Swiper, { Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

const prizeSwiper = new Swiper('.prize-swiper', {
    // configure Swiper to use modules
    modules: [Navigation, Autoplay],
    slidesPerView: 'auto',
    spaceBetween: 18,
    watchOverflow: true,
    autoplay: {
        delay: 2500,
        pauseOnMouseEnter: true
    },
    breakpoints: {
        768: {
            slidesPerView: 2,
            spaceBetween: 24,
        }
    },
    navigation: {
        prevEl: '.prize-swiper-control-prev',
        nextEl: '.prize-swiper-control-next'
    },

    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
    },
});

// Dynamically load the quiz
const getQuiz = () => import('./quiz');

if(document.getElementById('quizApp')) {
    getQuiz();
}

/* eslint-enable no-unused-vars */
